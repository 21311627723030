import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { BasisProvider } from 'basis';
import { useSiteTracking } from 'react-event-tracker';
import { HelmetProvider } from 'react-helmet-async';
import { trackingConfig } from '_config';
import { ContextProvider } from 'context';
import { DevFeatureToggle } from '__dev';
import { useDatadog, useTealium } from 'hooks';
import { ErrorBoundary, Metadata } from 'components';
import { theme } from 'theme';
import { Router } from './Router';
import { getSessionStorage, inferSource, isWithStartingLetter } from 'utils';
import { updateDataDogContext } from './hooks/useDatadog';
import { CountryCode, State } from 'types';

const SOURCE_KEY = 'source';
const ASSESSMENT_ID_KEY = 'assessmentId';
const CORRELATION_ID_KEY = 'correlationId';
const EMPLOYMENT_STATUS_KEY = 'empStatus';
const APP_NUMBER_KEY = 'appNum';
const CARD_TYPE_KEY = 'cardType';
const CHANNEL_KEY = 'channel';

const App: React.FC = () => {
  const url = new URL(window.location.href);
  const appNumInput = getParam(url, APP_NUMBER_KEY)?.toLowerCase();
  const sourceInput = getParam(url, SOURCE_KEY)?.toLowerCase() as State['source'] | undefined;
  const source = sourceInput || inferSource({ appNum: appNumInput, hostname: window.location.hostname });
  const assessmentId = getParam(url, ASSESSMENT_ID_KEY);
  const correlationId = getParam(url, CORRELATION_ID_KEY);
  const empStatus = getParam(url, EMPLOYMENT_STATUS_KEY);
  const cardType = getParam(url, CARD_TYPE_KEY);
  const channel = getParam(url, CHANNEL_KEY)?.toLowerCase();
  const queryParams = useMemo(
    () => ({
      source,
      assessmentId,
      correlationId,
      empStatus,
      /**
       * If the appNum starts with letter like 'f' or 's', it's from SMP AU QR code linked to the legacy NTT doc uploader.
       * See the full details at https://l-fs.slack.com/archives/C07F0B7VABY/p1733794830709149?thread_ts=1733721611.146089&cid=C07F0B7VABY
       */
      appNum: isWithStartingLetter(appNumInput) ? (appNumInput as string).slice(1) : appNumInput,
      cardType,
      channel: channel,
      countryCode: source?.split('-')[1].toUpperCase() as CountryCode,
    }),
    [source, assessmentId, correlationId, empStatus, appNumInput, cardType, channel],
  );

  useEffect(() => {
    updateDataDogContext(queryParams);
  }, [queryParams]);

  useDatadog();
  useTealium();

  const { SiteTracking } = useSiteTracking(trackingConfig);

  return (
    <HelmetProvider>
      <Metadata />
      <SiteTracking>
        <BasisProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <ContextProvider overrideInitState={queryParams}>
              <ErrorBoundary>
                <Router />
                <DevFeatureToggle />
              </ErrorBoundary>
            </ContextProvider>
          </ThemeProvider>
        </BasisProvider>
      </SiteTracking>
    </HelmetProvider>
  );
};

export default App;

function getParam<T extends string | undefined = undefined>(url: URL, key: string, defaultValue?: T): T extends string ? string : string | undefined {
  return (url.searchParams.get(key) || url.searchParams.get(key.toLowerCase()) || getSessionStorage('state', key) || defaultValue) as T extends string ? string : string | undefined;
}
