export { getAuthHeader, getLatitudeId, getSubFromToken, findOTPFactor } from './authUtils';
export { getTrackingEventData } from './analyticsUtils';
export { initFormConfig } from './initFormConfig';
export { initMessagesObject } from './initMessagesObject';
export { replaceStringVars } from './replaceStringVars';
export { RouterRedirectManager } from './RouterRedirectManager';
export { RouterScrollManager } from './RouterScrollManager';
export { oktaAuthClient } from './oktaAuthClient';
export { toDomesticMobileNumber, formatMaskedMobileNumber, toInternationalMobileNumber } from './phoneNumber';
export { setSessionStorage, getSessionStorage } from './sessionStorage';
export { submitHandler } from './submitHandler';
export { validation } from './validation';
export * from './state';
export * from './source';

export type { Messages } from './initMessagesObject';
export type { FormValidateFnc } from './validation';

export function isWithStartingLetter(input?: string) {
  if (!input) return false;
  return /^[a-zA-Z]/.test(input);
}
