import { State } from 'types';
import { isWithStartingLetter } from 'utils';

/**
 * Infer the source from the appNum. This is needed to support redirection for in-flight applications with email links to the legacy NTT doc uploader.
 * In these cases, the source is not passed in the URL, so that we need to infer it from the appNum.
 *
 * Rules in order of priority:
 * - if appNum starts with any single letter, like 'f' and 's', returns smp-au. This is sourced by SMP AU QR code.
 * - if appNum is 16 digits, this is a account number. Return cli-nz
 * - if appNum is 13 digits, this is an application ID. Then get the prefix of the ID and infer the source from that.
 * - if no appNum, use source of "website" and infer the country from the domain name.
 * - default to website-au (as AU has the highest number of users)
 *
 * Hopefully, we won't need source inference once migration graceful period is complete by March 2025.
 *
 * @param appNum - The appNum URL query param.
 * @param hostname - The hostname of the current page.
 * @returns The source.
 */
export function inferSource({ appNum, hostname }: { appNum?: string; hostname: string }): State['source'] {
  if (isWithStartingLetter(appNum)) {
    return 'smp-au';
  }

  if (appNum?.length === 16) {
    return 'cli-nz';
  }

  const applicationIdPrefix = appNum?.slice(0, 3);
  const inferredByPrefix = applicationIdPrefix ? prefixMappings[applicationIdPrefix as keyof typeof prefixMappings] : undefined;
  if (inferredByPrefix) {
    return inferredByPrefix;
  }

  switch (hostname) {
    case 'income.latitudefinancial.co.nz':
      return 'website-nz';
    case 'income.latitudefinancial.com':
    case 'income.latitudefinancial.com.au':
      return 'website-au';
    default:
      return 'website-au';
  }
}

const prefixMappings: Record<string, State['source']> = {
  '241': 'smp-au',
  '251': 'apply-nz',
  '500': 'cli-au',
  '990': 'smp-au',
};
